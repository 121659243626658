import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from "../contexts/AuthContext"
import { Button } from "react-bootstrap"
import { Link } from 'react-router-dom'

export default function Header(){
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        try {
          await logout()
          history.push("/login")
        } catch {
          console.log('failed to log out')
        }
    }

    return(
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
            <Link to="/" className='logo'>The Rousseeuw Prize for Statistics</Link>
            {
                currentUser ? (
                    <div className="navbar-collapse" id="navbarText">
                        <ul className="navbar-nav mr-auto">
                        </ul>
                        <Button variant="link" onClick={handleLogout}>
                            Log out
                        </Button>
                    </div>
                ) : (
                    <></>
                )
            }
        </nav>
    )
}