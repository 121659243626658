const subfields = {
    general_methodology: 'General statistical methodology',
    computational_data_science: 'Computational statistics and data science',
    biostatistics_environmetrics: 'Biostatistics and environmetrics',
    physical_sciences_industry: 'Statistics in the physical sciences and industry',
    economics_humanities: 'Statistics in economics and humanities'
  }
  
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

export {subfields, months}