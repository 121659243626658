import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useParams } from 'react-router-dom';

export default function PdfViewer() {
  const { id, url } = useParams()
  const decodedURL = decodeURIComponent(url)
  console.log(decodedURL)
  const finalURL = decodedURL.replace(`2024/${id}/`, `2024%2F${id}%2F`)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [canGoBack, setCanGoBack] = useState(false)
  const [canGoForward, setCanGoForward] = useState(false)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    if(numPages > 1) setCanGoForward(true)
  }

  const goForward = () => {
    if(pageNumber + 1 < numPages){
        setCanGoForward(true)
    } else{
        setCanGoForward(false)
    }
    setPageNumber(pageNumber + 1)
    setCanGoBack(true)
  }

  const goBack = () => {
    if(pageNumber - 2 === 0){
        setCanGoBack(false)
    } else{
        setCanGoBack(true)
    }
    setPageNumber(pageNumber - 1)
    setCanGoForward(true)
  }

  return (
    <div style={{"width": "90%", "alignSelf": "center"}}>
      <Document file={finalURL} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div id="pdf-controls">
        <p>
            Page {pageNumber} of {numPages}
        </p>
        <div id="pdf-buttons">
            <button className='btn btn-light' disabled={!canGoBack} onClick={() => goBack()}>Prev.</button>
            <button className='btn btn-light' disabled={!canGoForward} onClick={() => goForward()}>Next</button>
        </div>
      </div>
    </div>
  );
}