import React from "react"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Dashboard from "./Dashboard"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import Header from "./Header"
import Nomination from "./Nomination"
import "./styles.css"
import PdfViewer from "./PdfViewer"

function App() {
  return (
    <>
      <AuthProvider>
        <div id="content" className="w-100 h-100 d-flex flex-column" style={{"minHeight": "100vh", "paddingTop": "100px"}}>
          <Router>
            <Header />
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute path="/nomination/:id" component={Nomination} />
                <PrivateRoute path="/file/:id/:url" component={PdfViewer} />
                <Route path="/login" component={Login} />
              </Switch>
          </Router>
        </div>
      </AuthProvider>
    </>
  )
}

export default App
