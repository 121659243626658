import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../firebase'
import { months, subfields } from "../constants"
import { Link } from 'react-router-dom'
import { storage } from 'firebase'

export default function Nomination() {
  const { id } = useParams()
  document.title = `Dashboard - ${id}`
  const [submissionData, setSubmissionData] = useState({})
  const [filesData, setFilesData] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    fetchSubmissionData()
    fetchFilesData()
  }, [])

  const fetchSubmissionData = async () => {
    const ref = db.collection('2024').doc(id)
    const doc = await ref.get()
    try {
      if(doc.exists) {
        setSubmissionData(doc.data())
      } else{
        setError(true)
      }
    } catch (error) {
      setError(true)
    }
  }

  const fetchFilesData = async () => {
    const stor = storage()
    const ref = stor.ref(`2024/${id}`)
    ref.listAll().then(async (result) => {
        const data = await Promise.all(result.items.map(async (item) => {
            return {
                downloadURL: await item.getDownloadURL(),
                metadata: await item.getMetadata()
            }
        }))
        setFilesData(data)
      }).catch(function(error) {
        setError(true)
    });
  }

  return (
    <div style={{"width": "90%", "alignSelf": "center"}} id="nomination">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{id}</h2>
        </div>
        <hr />
        {
          Object.keys(submissionData).length !== 0 ? (
            <>
            <div className="card-deck">
              <div className="card bg-light mb-3">
                <div className="card-header">General information</div>
                <div className="card-body">
                  <p className="card-text"><b>Title: </b>{submissionData.title}</p>
                  <p className="card-text"><b>Subfield: </b>{subfields[submissionData.category]}</p>
                  <p className="card-text"><b>Submitted on: </b>{new Date(submissionData.timestamp.seconds*1000).toLocaleDateString("nl-BE")}</p>
                </div>
              </div>
              <div className="card bg-light mb-3">
                <div className="card-header">Nominator</div>
                <div className="card-body">
                  <p className="card-text"><b>Name: </b>{submissionData.nominator.name}</p>
                  <p className="card-text"><b>Affiliation: </b>{submissionData.nominator.affiliation}</p>
                  <p className="card-text"><b>E-mail: </b>{submissionData.nominator.email}</p>
                </div>
              </div>
            </div>
            <br />

            <h4>Nominees</h4>
            <hr />
            <div className='card-columns'>
              {
                submissionData.contributors.map((contributor) => (
                  <div className="card bg-light mb-3">
                    <div className="card-header">{contributor.name}</div>
                    <div className="card-body">
                      <p className="card-text"><b>Affiliation: </b>{contributor.affiliation}</p>
                      <p className="card-text"><b>E-mail: </b>{contributor.email}</p>
                    </div>
                  </div>
                ))
              }
            </div>
            <br />

            <h4>Files</h4>
            <hr />
            {
                filesData.length > 0 ? ( 
                  <div className='card-deck'>
                    {
                      filesData.map((file) => (
                        <div className="card">
                          <div className="card-body">
                            <p className="card-text">{file.metadata.name}</p>
                            <div className='d-flex flex-column'>
                              <Link to={`/file/${id}/${encodeURIComponent(file.downloadURL)}`}>
                                <button className="btn btn-primary mb-2 w-100">Preview</button>
                              </Link>
                              <a download href={file.downloadURL} target="_blank" rel="noopener noreferrer" className="btn btn-success">Download</a>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>) : (
                  <p>Loading files...</p>
                )
              }
            <br />
            <br />

            <h4>Motivations</h4>
            <hr />
            <div className="card bg-light mb-5">
              <div className="card-header"><b>Motivate why this contribution can be considered as pioneering work in statistical methodology. Describe why it is innovative.</b></div>
              <div className="card-body">
                <p className="card-text">{submissionData.motivateInnovation}</p>
              </div>
            </div>
            <div className="card bg-light mb-5">
              <div className="card-header"><b>Describe its impact on statistical research.</b></div>
              <div className="card-body">
                <p className="card-text">{submissionData.motivateResearch}</p>
              </div>
            </div>
            <div className="card bg-light mb-5">
              <div className="card-header"><b>Describe its impact on statistical practice and society.</b></div>
              <div className="card-body">
                <p className="card-text">{submissionData.motivatePractice}</p>
              </div>
            </div>
            <div className="card bg-light mb-5">
              <div className="card-header"><b>List its availability in (statistical) software.</b></div>
              <div className="card-body">
                <p className="card-text">{submissionData.motivateSoftware}</p>
              </div>
            </div>
            </>
          ) : (
            error ? (
              <p>There is no submission with this ID.</p>
            ) : (
              <p>Loading data...</p>
            )
          )
        }
    </div>
  )
}
