import React, { useState } from "react"
import { useEffect } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Link } from "react-router-dom";
import { db } from "../firebase"
import { months, subfields } from "../constants"

export default function Dashboard() {
  const [submissionsData, setSubmissionsData] = useState([])
  document.title = "Rousseeuw Prize Dashboard"

  let date = new Date()
  let month = months[date.getMonth()]
  let year = date.getFullYear()
  let day = date.getDate()

  useEffect(() => {
    fetchSubmissionsData()
  }, [])

  const fetchSubmissionsData = async () => {
    const response = db.collection('2024')
    const data = await response.get()
    data.docs.forEach(item=>{
      setSubmissionsData(previousData => [...previousData,{...item.data(), id: item.id}])
    })
  }

  return (
    <div style={{"width": "90%", "alignSelf": "center"}}>
      <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-primary"
          table="table"
          filename={`RP-${day}_${month}_${year}`}
          sheet={`RP-${day}_${month}_${year}`}
          buttonText="Generate Excel"
        />
      <div className="table-responsive">
        <table className="table table-striped mt-4" id="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Date</th>
              <th>Category</th>
              <th>Nominator Name</th>
              <th className="hidden">Nominator Affiliation</th>
              <th className="hidden">Nominator Email</th>
              <th className="hidden">Nominee 1 Name</th>
              <th className="hidden">Nominee 1 Affiliation</th>
              <th className="hidden">Nominee 1 Email</th>
              <th className="hidden">Nominee 2 Name</th>
              <th className="hidden">Nominee 2 Affiliation</th>
              <th className="hidden">Nominee 2 Email</th>
              <th className="hidden">Nominee 3 Name</th>
              <th className="hidden">Nominee 3 Affiliation</th>
              <th className="hidden">Nominee 3 Email</th>
              <th className="hidden">Nominee 4 Name</th>
              <th className="hidden">Nominee 4 Affiliation</th>
              <th className="hidden">Nominee 4 Email</th>
              <th className="hidden">Nominee 5 Name</th>
              <th className="hidden">Nominee 5 Affiliation</th>
              <th className="hidden">Nominee 5 Email</th>
            </tr>
          </thead>
          <tbody>
            {
              submissionsData.map((item) => (
                <tr>
                  <td><Link to={`/nomination/${item.id}`}>{item.id}</Link></td>
                  <td>{item.title}</td>
                  <td>{new Date(item.timestamp.seconds*1000).toLocaleDateString("nl-BE")}</td>
                  <td>{subfields[item.category]}</td>
                  <td>{item.nominator.name}</td>
                  <td className="hidden">{item.nominator.affiliation}</td>
                  <td className="hidden">{item.nominator.email}</td>
                  {
                    item.contributors.map((contributor) => (
                      <>
                        <td className="hidden">{contributor.name}</td>
                        <td className="hidden">{contributor.affiliation}</td>
                        <td className="hidden">{contributor.email}</td>
                      </>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}